
@font-face {
  font-family: 'Helvetica LT std light';
  src: url(./assets/fonts/HelveticaLTStd-Light.otf);
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica LT std bold';
  src: url(./assets/fonts/HelveticaLTStd-Bold.otf);
  font-display: swap;
}

body {
  margin: 0;
  max-height: 100%;
  overflow-y: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-primary {
  background-color: #a6192e !important;
}

.btn-outline-primary {
  border: 1px solid #a6192e !important;
  color: #a6192e;
}

.btn-outline-primary:hover {
  background-color: darkgray !important;
}

.btn-primary:hover {
  background-color: #700006 !important;
}

.bg {
  /* The image used */
  background-image: url("./assets/images/linkBg.jpg");

  /* Full height */
  width: auto;
  min-height: calc(100vh - 61px);

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.container-fluid {
  padding: 0 !important;
  min-height: calc(100vh - 61px);
  overflow: hidden;
}

@media  only screen and (min-width:0px) and (max-width:600px) {
    .resize-title-sm {
      font-size: 1.5rem !important;
    }
    .resize-text-sm {
      font-size: 1rem !important;
    }
  }

  @media  only screen and (min-width:0px) and (max-width:994px) {
    .resize-bg-sm {
      min-height: calc(100vh - 56px);
    }
  }